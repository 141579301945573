import Linkedin from "../../assets/images/socials/linkedinlogo.png";
import Gitlab from "../../assets/images/socials/gitlablogo.png";

const NavigationSocials = () => {
  return (
    <section className="social-media">
      <a
        href="https://www.linkedin.com/in/michael-maloney-10021/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Linkedin} alt="linkedin" />
      </a>
      <a
        href="https://gitlab.com/maloneym16"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Gitlab} alt="gitlab" />
      </a>
    
    </section>
  );
};

export default NavigationSocials;
