const PortfolioDescription = () => {
  return (
    <section className="portfolio-text">
      <h2 className="title-font pink-text h2-tag">Portfolio</h2>
      <p className="white-text p-tag">
        I've invested substantial time in a variety of projects, thoroughly enjoying the process of building things from the ground up. Below are some key projects that highlight my skills and passion. My experience includes a wide range of smaller projects across different courses and challenges, each focused on honing essential skills. You are welcome to browse these on my {" "}.
        <span>
          <a
            href="https://gitlab.com/maloneym16"
            className="pink-text"
            target="_blank"
            rel="noreferrer"
          >
            Gitlab
          </a>
        </span>
      </p>
    </section>
  );
};

export default PortfolioDescription;
