const experienceData = [
  {
    id: 1,
    jobtitle: "Market Manager",
    companyname: "Woodside Vineyards",
    worktype: "Full-time",
    duration: "Sept 2019 - Sept 2022",
    location: "Woodside, California",
    responsibility: [
      `Primary vineyard salesman responsible for building relationships with on-premise and off-premise customers - increased off-premise sales by 30% first year `,
      `Oversaw company marketing efforts including but not limited to email campaigns, wine promotions, and tasting events`,

    ],
  },
  {
    id: 2,
    jobtitle: "Market Manager",
    companyname: "DropRippleWave",
    worktype: "Full-time",
    duration: "Sept 2018 - Sept 2019",
    location: "New York, New York",
    responsibility: [`Sourced, developed, and maintained sales relationships with 20-30 key accounts, and another 50 potential/infrequent accounts`,
    `Honed an in-depth knowledge of beer, wine, and cider production processes for sales and marketing purposes`,
  ],
  },
  {
    id: 3,
    jobtitle: "Executive Recruiter",
    companyname: "Michael P Maloney Inc.",
    worktype: "Full-time",
    duration: "August 2013 - June 2017",
    location: "New York, New York",
    responsibility: [`Researched accounts and identified qualified candidate leads`,
    `Conducted interview with applicants and hiring managers looking for new employees`,
  ],
  }
];

const educationData = [
  {
    name: "TryHackMe",
    status: "Currently studying Pen Testing techniques",
    year: "2024",
  },
  {
    name: "Hack Reactor",
    status: "Full Stack Software Engineering",
    year: "2023",
  },
  {
    name: "Kedge Business School",
    status: "Master of Science: Wine and Spirits Management",
    year: "2018",
  },
  {
    name: "Dickinson College",
    status: "Bachelor of Arts: Environmental Studies",
    year: "2013",
  },
];

const certificationData = [
  {
    name: "CompTIA Security +",
    source: "from CompTIA",
    date: "Valid until December 2026"
  },
];

export { experienceData, educationData, certificationData };
