import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.sass';

const ContactForm = () => {
    const [formStatus, setFormStatus] = useState('Send Email');
    const [isSent, setIsSent] = useState(false);
    const [isError, setIsError] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        setFormStatus('Sending...');
        setIsSent(false);
        setIsError(false);

        emailjs.sendForm('default_service', 'template_1mv9xpt', e.target, 'K3ry2_cxoStpmeW_X')
            .then(() => {
                setFormStatus('Send Email');
                setIsSent(true);
                alert('Email successfully sent!!!');

            }, (err) => {
                setFormStatus('Send Email');
                setIsError(true);
                alert('Failed to send email. Please try again later');
                console.error('Failed to send email:', err);
            });
    };

    return (
        <div className="contact">
            <div className="contact-main">
                <form id="form" className="contact-form" onSubmit={sendEmail}>
                    <div className="contact-form-item">
                        <input type="text" name="fullname" placeholder="Full Name" />
                    </div>
                    <div className="contact-form-item">
                        <input type="email" name="email" placeholder="Email" />
                    </div>
                    <div className="contact-form-item">
                        <textarea name="message" placeholder="Your Message"></textarea>
                    </div>
                    <div className="contact-form-item">
                        <button type="submit" id="button">{formStatus}</button>
                    </div>
                </form>
                {isSent && <div className="form-success">Email successfully sent!</div>}
                {isError && <div className="form-error">Failed to send email! Please try again later!</div>}
            </div>
        </div>
    );
};

export default ContactForm;
