import ContactForm from "../contact/ContactForm";

const ContactPreview = () => {
  return (
    <article className="contact">
      <h2 className="title-font pink-text h2-tag">Contact Me</h2>
      <p className="white-text p-tag">
      I am currently looking for a full-time role in the cybersecurity and software engineering space. If you'd like to connect with me, please fill out the form below. Thank you for your time and consideration! 
      </p>
      <ContactForm />
    </article>
  );
};

export default ContactPreview;
