const AboutMe = () => {
  return (
    <section>
      <h2 className="title-font pink-text h2-tag">About Me</h2>
      <p className="white-text p-tag">
      I am a recent cybersecurity and software engineering graduate with extensive training in various network security frameworks and engineering principles. I have one year of technical programming experience building websites and applications. I am an energetic self-starter, problem-solver, and a team player with outstanding technical, analytical, and communication skills. I'm a fast learner with an interest in learning new software concepts.
      </p>
      <p className="white-text p-tag">
      My technical skills include Python, Javascript, SQL, React, HTML, CSS, Docker, Django, FastAPI, RESTful API, PostgreSQL, Windows, MacOS, Kali Linux, Nmap, Hydra, and I recently acquired the CompTIA Security + (702) certification.
      </p>
      <p className="white-text p-tag">
      I am currently looking for a full-time role in the cybersecurity and software engineering space. If you'd like to connect with me, please fill out the form below. Thank you for your time and consideration!
      </p>
    </section>
  );
};

export default AboutMe;
