
// import RestCountries from "../assets/images/portfolio/RestCountries.jpeg";
import glacefull2png from "../assets/images/portfolio/glacefull2png.png";
import moviemania from "../assets/images/portfolio/moviemania.png";
import carcar from "../assets/images/portfolio/carcar.png";

const projectsData = [
  {
    id: "Glace-Noglu",
    img: glacefull2png,
    name:"Glace by Noglu" ,
    stack: ["< React.js />", "< Javascript />"],
    src: "https://glaceny.com",
    source: "https://gitlab.com/maloneym16/michael-maloney-ice-cream",
    description:
      "Designed, built, and deployed an ice cream landing page for New York Cities newest icecream store-front",
  },
  {
    id: "Movie-Maniacs",
    img: moviemania,
    name: "Movie Maniacs",
    stack: ["< Fast API />", "< CSS3 />", "< React.js />", "< Rover />", "< Docker />",],
    src: "https://gitlab.com/maloneym16/michael-maloney-movie-maniacs",
    source: "https://gitlab.com/maloneym16/michael-maloney-movie-maniacs",
    description:
      "Built and deployed a movie database web application using React, Fast API, Rover, and Docker Implemented two third party APIs to integrate our movie database as well as our Where To Watch feature Leveraged modern web development best practices and technologies to create a fast and responsive user experience"
      ,
  },

  {
    id: "CarCar",
    img: carcar,
    name: "Car Car",
    stack: ["< RESTful API />", "< React/js />", "< Django />", "< Docker />"],
    src: "https://gitlab.com/maloneym16/car-car	",
    source: "https://gitlab.com/maloneym16/car-car	",
    description:
      "Developed RESTful APIs that would retrieve data from the inventory microservice and use it to model the Auto Sales backend. Utilized React to design the front-end, capturing user input and displaying data from the backend microservices. Employed Django to create Docker containerized microservices that processed API requests, delivering data to the user.",
  },
];

export { projectsData };
